<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add New Speaker
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addSpeakerFormalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Full Name"
              label-for="blog-edit-title"
              class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Speaker Name"
                rules="required">
                <b-form-input
                data-testid="speaker-name"
                  v-model="addSpeakerForm.full_name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="title" label-for="blog-edit-slug" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
                rules="required">
                <b-form-input
                data-testid="speaker-title"
                  v-model="addSpeakerForm.title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
      

          <b-col cols="12" class="mb-2">
            <div class="border rounded p-2">
              <h4 class="mb-1">image</h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row">
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0" />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewE2.src = Tabimage;
                      addSpeakerForm.image = '';
                    ">
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2">
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image ">
                        <b-form-file
                        data-testid="speaker-image"
                          ref="refInputE2"
                          v-model="addSpeakerForm.image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2">
                      <b-form-input
                        id="alt-image"
                        v-model="addSpeakerForm.alt_image" />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group label="about" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="about">
                <quill-editor
                  ref="aboutEditor"
                  v-model="addSpeakerForm.about"
                  :options="getEditorOptions('aboutEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-50">
            <b-button
            data-testid="submit-button"
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save">
              Save Changes
            </b-button>

            <b-button v-if="isLoading" variant="primary" disabled class="mr-1">
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
      <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive,getCurrentInstance } from "@vue/composition-api";
import Vue from "vue";
import Editor from "@tinymce/tinymce-vue";
import "flatpickr/dist/flatpickr.css";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.snow.css'
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup(props, { emit }) {
    // const store = useStore();
    const refInputEl = ref(null);
    const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
    const refInputE3 = ref(null);
    const refPreviewE3 = ref(null);
    const isLoading = ref(false);
    const refPreviewE2 = ref(null);

    const Tabimage = ref("media/svg/files/blank-image.svg");
    const validLink = ref(true);
    const checkLink = (link) => {
      const formData = new FormData();

      formData.append("slug", link);
      store.dispatch("courses/checkLink", formData).then((response) => {
        validLink.value = response.data.valid;
      });
    };

    const addSpeakerForm = reactive({
      title: "",
      image: [],

      alt_image: "",

      full_name: "",

      about: "",
    });
    const SpeakersList = ref([]);

    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )

    const addSpeakerFormalidate = ref();
    const form = ref();

    const save = () => {
      addSpeakerFormalidate.value.validate().then((success) => {
        if (success) {
          isLoading.value = true;
          const formData = new FormData();

          formData.append("title", addSpeakerForm.title);
          formData.append("image", addSpeakerForm.image);
          formData.append("alt_image", addSpeakerForm.alt_image);

          formData.append("full_name", addSpeakerForm.full_name);

          formData.append("about", addSpeakerForm.about);

          store
            .dispatch("speakers/addspeakers", formData)
            .then((response) => {
              addSpeakerForm.title=''
              
              addSpeakerForm.alt_image=''
              addSpeakerForm.about=''
              addSpeakerForm.full_name=''
              form.value.reset();
              isLoading.value = false;
            
              refPreviewE2.value.src = "media/svg/files/blank-image.svg";
              Vue.swal({
                title: "Speaker Added ",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              isLoading.value = false;
              Vue.swal({
                title: "",
                text: `${error.response.data.message}`,
                icon: "error",
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    };
    function UploadImage(blobInfo, success, failure, progress) {
      let xhr;
      let formData;
      const token = localStorage.getItem("token");

      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`);

      xhr.upload.onprogress = function (e) {
        progress((e.loaded / e.total) * 100);
      };
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.onload = function () {
        let json;

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`);
          return;
        }

        json = JSON.parse(xhr.responseText);

        if (!json || typeof json.location !== "string") {
          failure(`Invalid JSON: ${xhr.responseText}`);
          return;
        }

        success(json.location);
      };

      xhr.onerror = function () {
        failure(
          `Image upload failed due to a XHR Transport error. Code: ${xhr.status}`
        );
      };

      formData = new FormData();
      formData.append("image", blobInfo.blob(), blobInfo.filename());

      xhr.send(formData);
    }
     const { proxy } = getCurrentInstance()
    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


  const linkHandler = editorRef => {
  const range = proxy.$refs[editorRef].quill.getSelection()
  if (range) {
    const url = prompt('Enter the URL')
    if (url) {
      const openInNewTab = confirm('Open in new tab?')
      const selectedText = proxy.$refs[editorRef].quill.getText(range)
      const targetAttribute = openInNewTab ? ' target="oooooooooo" rel="noopener noreferrer"' : ''
      const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

      // Remove the selected text
      proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

      // Insert the link at the original selection index
      proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
    }
  }
}

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
    ['bold', 'italic', 'underline', 'strike'],
  
    ['clean'],
    ['undo', 'redo'],
    ['paragraph']
          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef)
          },
        },
      },
    })

    return {
      UploadImage,
      SpeakersList,
      refInputEl,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      refInputE3,
      refPreviewE3,
      Tabimage,
      addSpeakerForm,
      inputImageRendererTab,
      addSpeakerFormalidate,

      form,
      validLink,
      checkLink,
      isLoading,
 linkHandler,
       imageHandler,
      insertImage,
      imageProperties,
      getEditorOptions,
      save,
      required,
      email,
    };
  },

  components: {
    BCard,
    BMedia,
    vSelect,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    Editor,
    flatPickr,
    BForm,

    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
</style>
